import React, { Fragment } from 'react'
import { graphql } from 'gatsby';

import SEO from "../App/components/seo"
import Layout from '../App/components/layout';
import ManufatureIcons from '../App/components/manufatureIcons';
import SignInForm from '../components/SignIn';
  import OnSendEmailVerificationButton from '../components/Session/onSendEmailVerification';
  import { SignUpLink } from '../components/SignUp';
  import { PasswordForgetLink } from '../components/PasswordForget';
  import AuthUserContext from '../components/Session/context';



const manufatureIconsList = ({data}) => (
    <Layout>
        <SEO title="" />
        <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          authUser.emailVerified ? (
       <ManufatureIcons manufatuerersObject={data.craft.categories}/>
          ):(
            <OnSendEmailVerificationButton/>
          )
          ) : (
            <Fragment>
                <SignInForm />
                <div className="wrapper maxWidth"><PasswordForgetLink /></div>
                <SignUpLink />
              </Fragment>
          )}
          </AuthUserContext.Consumer>
    </Layout>

);

export default manufatureIconsList;

export const data = graphql`{
    craft{
        categories(group: manufacture, orderBy:"title asc"){
            ...on Craft_ManufactureCategory{
                title
                asset{
                    url
                    id
                }
            }
        }
    }
}`;

      //
